import type { AuthorisedUser, TranslationKey } from '@nexdynamic/squeegee-common';
import { AlphanumericCharColourDictionary, ProductLevel, SyncMode, randomInteger, wait } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable, computedFrom } from 'aurelia-framework';
import type { Router } from 'aurelia-router';
import moment from 'moment';
import { pwaInstallHandler } from 'pwa-install-handler';
import { EditProfileDialog } from '../Account/EditProfileDialog';
import { ApplicationState } from '../ApplicationState';
import type { IAvatar } from '../Components/IAvatar';
import { Data } from '../Data/Data';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Prompt } from '../Dialogs/Prompt';
import { ApplicationStateUpdatedEvent } from '../Events/ApplicationStateUpdatedEvent';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import { PermissionsUpdateEvent } from '../Events/PermissionsUpdateEvent';
import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import type { IRoute } from '../IRoute';
import { Logger } from '../Logger';
import { RouteUtil } from '../Routes';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { SettingsDialog } from '../Settings/SettingsDialog';
import { Utilities, animate } from '../Utilities';

import packageJson from '../../package.json';
import { GlobalFlags } from '../GlobalFlags';

export class MenuDrawer {
    getRolesText(): TranslationKey | '' {
        if (!ApplicationState.userAuthorisation.roles?.length) return '';
        const roleTranslationKeys: Array<TranslationKey> = [];

        for (const role of ApplicationState.userAuthorisation.roles) {
            roleTranslationKeys.push(`role.${role}`);
        }

        return Utilities.localiseList(roleTranslationKeys);
    }
    @bindable private router: Router;
    public email = RethinkDbAuthClient.session && RethinkDbAuthClient.session.email;
    public account = ApplicationState.account;
    public applicationState = ApplicationState;
    public stateFlags = ApplicationState.stateFlags;
    public decrementDevModeCounter = ApplicationState.decrementShowDevModeCounter;

    private _api = Api;

    protected integrationRoutes = ApplicationState.integrationRoutes;
    protected accountRoutes: Array<IRoute>;
    protected franchiseRoutes: Array<IRoute>;
    protected workRoutes: Array<IRoute>;
    protected customerRoutes: Array<IRoute>;
    protected billingRoutes: Array<IRoute>;
    protected financeRoutes: Array<IRoute>;
    protected configureRoutes: Array<IRoute>;
    protected socialRoutes: Array<IRoute>;
    protected sendRoutes: Array<IRoute>;
    protected balanceRoutes: Array<IRoute>;
    protected avatar: IAvatar;
    protected weatherIcon: string;
    protected weatherHigh: string;
    protected weatherLow: string;
    protected showConnectedAccountsMenu = false;
    protected accounts: Array<AuthorisedUser> = [];
    protected selectedAccount?: AuthorisedUser;
    protected multiUserEnabled: boolean;

    protected _LauncherSubscription: Subscription;

    allRoutes: IRoute[];
    weatherSummary: string;

    private _compactClassDesktop = '';
    @computedFrom('_compactClassDesktop')
    protected get compactClassDesktop() {
        return this._compactClassDesktop;
    }
    private _compactClassMobile = '';
    @computedFrom('_compactClassMobile')
    protected get compactClassMobile() {
        return this._compactClassMobile;
    }

    @computedFrom('_api.itemsAwaitingSync')
    protected get itemsToSync() {
        return this._api.itemsAwaitingSync;
    }

    @computedFrom('_api.isConnected')
    protected get isConnected() {
        return this._api.isConnected;
    }

    @computedFrom('applicationState.stateFlags.devMode', 'applicationState.account.businessLogoAsAvatar')
    protected get showBusinessLogo() {
        return !this.applicationState.stateFlags.devMode && this.applicationState.account.businessLogoAsAvatar;
    }

    @computedFrom('applicationState.stateFlags.devMode', 'applicationState.account.businessLogoAsAvatar')
    protected get showAvatar() {
        return !this.applicationState.stateFlags.devMode && !this.applicationState.account.businessLogoAsAvatar;
    }

    protected reloadApp() {
        Utilities.goToRootUrl({ queryStringParams: { rnd: randomInteger(1000, 10000) } });
    }

    protected async showSyncStatusInfo() {
        if (this.notSubscribed) return;

        let message: TranslationKey;

        if (this.itemsToSync === undefined) await Api.updateItemsAwaitingSync();

        if (!this.itemsToSync) {
            message = 'prompts.sync-status-synced';
        } else if (this.itemsToSync === 1) {
            if (Api.isConnected) {
                message = 'prompts.sync-status-items-syncing-one';
            } else {
                message = 'prompts.sync-status-offline-items-not-synced-one';
            }
        } else {
            if (Api.isConnected) {
                message = 'prompts.sync-status-items-syncing-many';
            } else {
                message = 'prompts.sync-status-offline-items-not-synced-many';
            }
        }

        const syncInfo = new Prompt('prompts.sync-status-title', message, {
            okLabel: 'general.ok',
            cancelLabel: '',
            localisationParams: { items: (this.itemsToSync && this.itemsToSync.toFixed(0)) || '0' },
            allowClickOff: true,
        });

        await syncInfo.show();
    }

    private _colours = new AlphanumericCharColourDictionary();

    protected myRoles = this.getRolesText();

    protected notSubscribed: boolean;

    private _isFranchiseOwnerOrAdminInFranchiseeAccount?: boolean;

    @computedFrom('_isFranchiseOwnerOrAdminInFranchiseeAccount')
    protected get isFranchiseOwnerOrAdminInFranchiseeAccount() {
        if (this._isFranchiseOwnerOrAdminInFranchiseeAccount === undefined) {
            this._isFranchiseOwnerOrAdminInFranchiseeAccount = false;
            if (ApplicationState.isFranchise()) return false;

            if (!ApplicationState.instance.franchiseOwnerEmail) return false;
            if (ApplicationState.instance.franchiseOwnerEmail === ApplicationState.dataEmail) return false;
            const url = `/api/franchise/is-franchise-owner-or-admin?franchiseOwnerEmail=${ApplicationState.instance.franchiseOwnerEmail}`;
            Api.get<{ isFranchiseOwnerOrAdmin: boolean }>(null, url).then(result => {
                this._isFranchiseOwnerOrAdminInFranchiseeAccount = !!result?.data.isFranchiseOwnerOrAdmin;
            });
        }
        return !!this._isFranchiseOwnerOrAdminInFranchiseeAccount;
    }
    protected goToFranchiseTabInMainFranchise = () => {
        if (!this.isFranchiseOwnerOrAdminInFranchiseeAccount) return;

        if (!ApplicationState.instance.franchiseOwnerEmail) return;

        Utilities.goToRootUrl({
            applicationRouteWithLeadingSlash: '/franchise',
            queryStringParams: {
                dataEmail: ApplicationState.instance.franchiseOwnerEmail,
            },
        });
    };
    private async initRoutes(isRefresh = false) {
        this._compactClassDesktop = ApplicationState.getSetting('theme.compact-menus-desktop', false) ? 'compact-desktop' : '';
        this._compactClassMobile = ApplicationState.getSetting('theme.compact-menus-mobile', false) ? 'compact-mobile' : '';
        const subscription =
            isRefresh || ApplicationState.hasCachedSubscription
                ? ApplicationState.subscription
                : await ApplicationState.updateSubscription();

        this.notSubscribed =
            (subscription && subscription.product && subscription.product.productLevel === ProductLevel.NotSubscribed) || false;

        this.allRoutes = this.notSubscribed
            ? RouteUtil.getRoutes().filter(x => x.name === 'users')
            : RouteUtil.getRoutes().filter(route => ApplicationState.canNavigateTo(route));

        this.integrationRoutes = ApplicationState.integrationRoutes;
        this.franchiseRoutes = this.allRoutes.filter(route => route.settings.showInNav === 'franchise');
        this.workRoutes = this.allRoutes.filter(route => route.settings.showInNav === 'work');
        this.customerRoutes = this.allRoutes.filter(route => route.settings.showInNav === 'customers');
        this.billingRoutes = this.allRoutes.filter(route => route.settings.showInNav === 'billing');
        this.financeRoutes = this.allRoutes.filter(route => route.settings.showInNav === 'finance');
        this.balanceRoutes = this.allRoutes.filter(route => route.settings.showInNav === 'balance');

        this.configureRoutes = this.allRoutes.filter(route => route.settings.showInNav === 'configure');
        this.socialRoutes = this.allRoutes.filter(route => route.settings.showInNav === 'social');
        this.sendRoutes = this.allRoutes.filter(route => route.settings.showInNav === 'send');
    }

    private menuDrawerBtn: HTMLButtonElement;

    private _applicationStateUpdatedEventSub: Subscription;
    private _permissionsUpdatedEvent: Subscription;

    @computedFrom('account.name', 'selectedAccount')
    protected get accountName() {
        if (this.selectedAccount) {
            return this.selectedAccount.userName;
        }
        return this.account.name;
    }

    protected devMode = ApplicationState.stateFlags.devMode;

    private _updatingAccountList = false;
    private async updateAccountsList() {
        if (this._updatingAccountList) return;

        this._updatingAccountList = true;
        try {
            this.multiUserEnabled = ApplicationState.multiUserEnabled;
            let accounts = Api.cachedAccounts;
            Api.getAccounts();
            let timeout = 500;
            while (!accounts) {
                await wait((timeout += timeout));
                accounts = await Api.getAccounts();
            }

            if (accounts) {
                this.accounts = accounts.filter(x => !x.deactivated);
                if (this.accounts.length && ApplicationState.dataEmail) {
                    this.selectedAccount = this.accounts.find(x => x.dataEmail === ApplicationState.dataEmail);
                    this.multiUserEnabled =
                        ApplicationState.multiUserEnabled || this.accounts.filter(x => !!x.roles && x.roles.length > 0).length > 1;
                } else {
                    ApplicationState.setAccountOverride();
                }

                if (this.accounts.length === 1) this.showConnectedAccountsMenu = false;
            }
        } finally {
            this._updatingAccountList = false;
        }
    }

    private async refresh() {
        this.account = ApplicationState.account;
        this.setAvatar();
        await this.initRoutes(true);
        this.updateAccountsList();
        this.isOwnerOrAdmin = ApplicationState.isInAnyRole(['Owner', 'Admin']);
        this.isOwner = ApplicationState.isInAnyRole(['Owner']);
        this.myRoles = this.getRolesText();
    }

    protected isOwnerOrAdmin = ApplicationState.isInAnyRole(['Owner', 'Admin']);

    protected isOwner = ApplicationState.isInAnyRole(['Owner']);

    private dataRefreshedEventSub: Subscription;
    public async attached() {
        this.setAvatar();
        await this.initRoutes();

        ApplicationState.refreshNavigation = () => this.refresh();

        this._applicationStateUpdatedEventSub = ApplicationStateUpdatedEvent.subscribe<ApplicationStateUpdatedEvent>(() => this.refresh());

        this.dataRefreshedEventSub = DataRefreshedEvent.subscribe<DataRefreshedEvent>(e => {
            if (e.hasAnyType('applicationstate')) this.refresh();
        });

        this._permissionsUpdatedEvent = PermissionsUpdateEvent.subscribe(() => {
            this.refresh();
        });

        this.getWeather();

        this.menuDrawerBtn = <HTMLButtonElement>document.querySelector('.mdl-layout__drawer-button');
        // Stupid hack to stop default behaviour of menu drawer button
        const content: any = document.querySelector('.mdl-layout');
        if (content && content.MaterialLayout) delete content.MaterialLayout.drawer;

        if (this.menuDrawerBtn) {
            this.menuDrawerBtn.onclick = this.toggleDrawer;
        }

        if (!GlobalFlags.isMobile && this.menuDrawerBtn) this.menuDrawerBtn.click();

        this.updateAccountsList();

        const isAdminOrOwner = ApplicationState.isInAnyRole(['Owner', 'Admin']);
        const quickStartVisible = !ApplicationState.getSetting('global.hide-quick-start', false);

        const subscriptionHasNoStatus = ApplicationState.subscription.status === undefined;
        const accountUnverified = RethinkDbAuthClient.session?.unverified;
        const isAllowedRoute = ['account/subscription', 'email-settings'].some(x => document.location.href.includes(x));
        const sendToQuickStart = !isAllowedRoute && quickStartVisible && isAdminOrOwner && subscriptionHasNoStatus;

        // Check given the new routes that the current route is still ok
        await animate();

        if (sendToQuickStart) return ApplicationState.navigateTo('quickstart');
        if (accountUnverified) return ApplicationState.navigateTo('verify-email');

        if (!this.notSubscribed) return ApplicationState.showReleaseNotesIfNew(); // SubscribedShow release notes if new

        if (!ApplicationState.canShowAccountManagement) return ApplicationState.navigateTo('about'); // Apple app store requires a page to be shown

        if (ApplicationState.subscription.status === 'unpaid') return ApplicationState.navigateTo('account'); // Unpaid

        return ApplicationState.navigateTo('account-subscription'); // Not subscribed
    }

    protected canInstall = ApplicationState.canInstall;

    protected async install() {
        const installed = await pwaInstallHandler.install();
        Logger.info(installed ? 'User accepted installation prompt' : 'User rejected installation prompt');
    }

    private async getWeather() {
        if (ApplicationState.weather) {
            const weather = ApplicationState.weather[moment().format('YYYY-MM-DD')];
            if (weather) {
                this.weatherIcon = 'images/weather/' + weather.icon + '.svg';
                this.weatherSummary = weather.summary;
                this.weatherHigh =
                    (weather.high &&
                        (ApplicationState.temperatureUnits === 'fahrenheit'
                            ? weather.high.toFixed(0) + '°F'
                            : ApplicationState.fahrenheitToCelsius(weather.high) + '°C')) ||
                    '';
                this.weatherLow =
                    (weather.low &&
                        (ApplicationState.temperatureUnits === 'fahrenheit'
                            ? weather.low.toFixed(0) + '°F'
                            : ApplicationState.fahrenheitToCelsius(weather.low) + '°C')) ||
                    '';
            } else {
                this.weatherIcon = '';
            }
        }
    }

    public detached() {
        this._applicationStateUpdatedEventSub && this._applicationStateUpdatedEventSub.dispose();
        this._permissionsUpdatedEvent && this._permissionsUpdatedEvent.dispose();
        this.dataRefreshedEventSub?.dispose();
        this._LauncherSubscription?.dispose();
        window.removeEventListener('popstate', this.closeMenu);
    }

    private closeMenu = () => {
        if (GlobalFlags.isMobile && this.isOpen) this.hideDrawer();
    };

    private setAvatar() {
        const accountName = this.accountName && this.accountName.length > 0 ? this.accountName : '?';

        const parts = accountName.trim().split(' ');
        let text = parts[0].substring(0, 1).toUpperCase();
        const colour = this._colours[text];
        if (parts.length > 1) text += parts[parts.length - 1].substring(0, 1).toUpperCase();
        text += ' ';
        text = text.substring(0, 2);
        this.avatar = { text, colour };
    }

    public changeRoute(event: Event | undefined, routeOrRouteHref: IRoute | string, replace = false): void {
        // TODO don't make it take a string or IRoute make 2 methods
        event && event.stopPropagation();

        let routeHref = routeOrRouteHref;

        if (typeof routeOrRouteHref !== 'string') {
            if (!ApplicationState.canNavigateTo(routeOrRouteHref)) return;

            if (routeOrRouteHref.href) {
                routeHref = routeOrRouteHref.href;
            } else {
                routeHref = `/${(routeOrRouteHref.route as string).split('/:')[0]}`;
            }
        }

        // WTF does this route require a replace?
        if (typeof routeOrRouteHref !== 'string') {
            if (!replace) replace = Boolean(routeOrRouteHref.settings.replace);
        }

        // WTF this allows us to force aurelia to reload a module when we are already on it instead of doing nothing.
        // If we were to replace every time then react routes won't be pushed into history and we can't go back to them.
        // Its not pretty and aurelia seems to hyjack the history causing forward history to break
        const doReplace = replace && this.router.currentInstruction?.config.href === routeHref;

        if (typeof routeHref === 'string') {
            this.router.navigate(routeHref, { replace: doReplace });
        }

        // IOS Hack for the menu events bubbling.
        if (GlobalFlags.isMobile)
            setTimeout(() => (!document.querySelector || document.querySelector('#menuDrawer.is-visible')) && this.hideDrawer(), 0);
    }

    public signedIn() {
        return RethinkDbAuthClient.session;
    }

    letMeBeDev(_event: Event) {
        ApplicationState.stateFlags.devMode = !ApplicationState.stateFlags.devMode;
        ApplicationState.stateFlags.showDevModeCounter = 20;
    }

    stopIt(event: Event) {
        event.preventDefault();
    }

    public async openSettingsDialog(event: Event) {
        event.stopPropagation();
        try {
            // IOS Hack for the menu events bubbling.
            if (GlobalFlags.isMobile)
                setTimeout(() => (!document.querySelector || document.querySelector('#menuDrawer.is-visible')) && this.hideDrawer(), 0);

            const dialog = new SettingsDialog();
            await dialog.show(DialogAnimation.SLIDE);
        } catch (error) {
            Logger.error(`Error during openSettingsDialog in menu drawer`, error);
        }
    }

    protected selectAccount(account: AuthorisedUser) {
        if (!Api.isConnected)
            return new Prompt(
                'Not Connected' as TranslationKey,
                'You are currently not connected to the Squeegee servers so you cannot currently switch accounts.' as TranslationKey,
                { cancelLabel: '' }
            ).show();

        Utilities.switchAccount(account.dataEmail);
    }

    protected version = MenuDrawer._getVersionDescription();

    private static _getVersionDescription() {
        return `v${packageJson.version}`;
    }
    protected toggleManageAccountMenu = () => {
        if (this.accounts.length === 1) return;
        this.showConnectedAccountsMenu = !this.showConnectedAccountsMenu;
    };

    protected editProfile(event: Event) {
        event.stopPropagation();
        try {
            new EditProfileDialog().show(DialogAnimation.SLIDE);
            setTimeout(() => {
                if (GlobalFlags.isMobile && this.isOpen) this.hideDrawer();
            }, 50);
        } catch (error) {
            Logger.error(`Error during edit profile in menu drawer`, error);
        }
    }

    protected isAppleMobileApp = !ApplicationState.canShowAccountManagement;

    protected manageAccount(event: Event) {
        event && event.stopPropagation();
        if (!this.isOwnerOrAdmin) return;
        try {
            ApplicationState.router.navigateToRoute('account');
            setTimeout(() => {
                if (GlobalFlags.isMobile && this.isOpen) this.hideDrawer();
            }, 50);
        } catch (error) {
            Logger.error('Error in manageAccount() on MenuDrawer', error);
        }
    }

    protected async signOut(event: Event) {
        event.stopPropagation();

        await ApplicationState.signOut();

        if (GlobalFlags.isMobile && this.isOpen) this.hideDrawer();
    }

    private get isOpen() {
        return !!document.querySelector('#menuDrawer.is-visible');
    }

    private toggleDrawer = () => {
        const view = document.getElementById('main-view');
        if (view) view.classList.toggle('menu-drawer-shown');
        new ViewResizeEvent();
    };

    public hideDrawer = () => {
        this.showConnectedAccountsMenu = false;
        this.menuDrawerBtn.click();
    };

    protected async syncNow() {
        if (GlobalFlags.isMobile) this.hideDrawer();

        await Data.fullSync(SyncMode.Partial);
    }

    protected onSupportActionClicked() {
        this.closeMenu();
    }

    @computedFrom('applicationState.profileImage')
    protected get profileImg() {
        return this.applicationState.profileImage;
    }
}
